<template>
  <div>
    <!-- delete confirmation modal -->
    <v-dialog v-model="deleteModelModal" max-width="500px">
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          <p>
            Are you sure want to delete model
            <b>'{{ deletingModel.name }}'</b>?
          </p>
          <p class="mt-3">
            Data generated by this model will <b>not</b> be lost.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn text v-on:click.native="deleteModelModal = false" small outlined class="secondary--text">Cancel</v-btn>
          <v-spacer> </v-spacer>
          <v-btn v-on:click.native="deleteModel(deletingModel)" small text class="primary white--text">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container container--fluid>
      <v-layout wrap class="justify-center">
        <v-flex lg3 md4 sm12>
          <v-card flat class="ma-2">
            <v-card-title class="secondary--text text-subtitle-1">
              <v-icon class="mr-2" small>mdi-database-outline</v-icon>

              <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }"> 
                    Work Queue
                  </span>
                </template>
                Last 30 days
              </v-tooltip>
            </v-card-title>
            <v-card-text>

              <v-skeleton-loader v-if="imageGenStatsLoading" class="mx-auto" max-width="300" height="145"
                type="article, actions"></v-skeleton-loader>

              <v-simple-table v-else dark>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="secondary--text">Pending</td>
                      <td>
                        <span v-if="imageGenStats.pending > 0 || imageGenStats.processing > 0"
                          class="font-weight-bold align-end">{{ imageGenStats.pending + imageGenStats.processing }}</span>
                        <span v-else class="font-weight-bold">0</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="secondary--text">Completed</td>
                      <td>
                        {{ imageGenStats.completed }}
                      </td>
                    </tr>

                    <tr>
                      <td class="secondary--text">Failed</td>
                      <td>
                        {{ imageGenStats.failed }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex lg3 md4 sm12>
          <v-card flat class="ma-2">
            <v-card-title class="secondary--text text-subtitle-1">
              <v-icon class="mr-2" small>inventory_2</v-icon>              
              <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }"> 
                    Storage
                  </span>
                </template>
                All your generated images, prompts and text responses
              </v-tooltip> 
            </v-card-title>
            <v-card-text>

              <v-skeleton-loader v-if="imageGenStatsLoading" class="mx-auto" max-width="300" height="145"
                type="article, actions"></v-skeleton-loader>

              <v-simple-table v-else dark>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="secondary--text">Total size</td>
                      <td>
                        <span v-if="imageGenStats.totalSize > 0" class="font-weight-bold">{{ imageGenStats.totalSize |
                          size }}</span>
                        <span v-else class="font-weight-bold">0B</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="secondary--text">Files</td>
                      <td>
                        {{ imageGenStats.totalFiles }}
                      </td>
                    </tr>

                    <tr>
                      <td class="secondary--text"></td>
                      <td></td>
                    </tr>

                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex lg3 md4 sm12>
          <v-card flat class="ma-2">
            
              <v-card-title class="secondary--text text-subtitle-1">
                <v-icon class="mr-2" small>mdi-cash</v-icon>
                <v-tooltip right>
                  <template v-slot:activator="{ on: tooltip }">
                    <span v-on="{ ...tooltip }"> 
                      Credits Charged
                    </span>
                  </template>
                  Last 30 days
                </v-tooltip>              
              </v-card-title>              
            
            <v-card-text>
              <v-skeleton-loader v-if="modelsLoading" class="mx-auto" max-width="300" height="145"
                type="article, actions"></v-skeleton-loader>

              <v-simple-table v-else dark>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="secondary--text">Text models</td>
                      <td>                      
                        <v-tooltip v-if="textGenStats.cost && textGenStats.cost < 0" top>
                          <template v-slot:activator="{ on: tooltip }">
                            <span class="font-weight-bold" v-on="{ ...tooltip }">
                              {{ textGenStats.cost.toFixed(4) | abs }}
                            </span>                           
                          </template>
                          {{ textGenStats.cost | abs }}
                        </v-tooltip>
                        <span v-else>N/A</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="secondary--text">Image models</td>
                      <td>
                        <span class="font-weight-bold">
                          <v-tooltip v-if="imageGenStats.cost && imageGenStats.cost < 0"  top>
                          <template v-slot:activator="{ on: tooltip }">
                            <span class="font-weight-bold" v-on="{ ...tooltip }">
                              {{ imageGenStats.cost.toFixed(4) | abs }}
                            </span>
                          </template>
                          {{ imageGenStats.cost | abs }}
                        </v-tooltip>
                        <span v-else>N/A</span>
                        </span>                       
                      </td>
                    </tr>

                    <tr>
                      <td class="secondary--text"></td>
                      <td></td>
                    </tr>

                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex lg3 md4 sm12>
          <v-card flat class="ma-2">
            <v-card-title class="secondary--text text-subtitle-1">
              <v-icon class="mr-2" small>mdi-rocket-launch-outline</v-icon>
              Performance
            </v-card-title>
            <v-card-text>

              <v-skeleton-loader v-if="imageGenStatsLoading" class="mx-auto" max-width="300" height="145"
                type="article, actions"></v-skeleton-loader>

              <v-simple-table v-else dark>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="secondary--text">95 percentile</td>
                      <td>
                        <span v-if="imageGenStats.percentile50th > 0" class="font-weight-bold">{{
                          imageGenStats.percentile95th / 1000 }}s</span>
                        <span v-else>N/A</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="secondary--text">Median</td>
                      <td>
                        <span v-if="imageGenStats.percentile50th > 0" class="font-weight-bold">{{
                          imageGenStats.percentile50th / 1000 }}s</span>
                        <span v-else>N/A</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="secondary--text"></td>
                      <td></td>
                    </tr>

                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-flex>

      </v-layout>
    </v-container>

    <v-layout wrap class="justify-center ml-2">
      <v-flex lg12 md12 sm12 pr-2>
        <v-container container--fluid>
          <v-card flat height="100%" min-height="150">

            <v-tabs flat dense v-model="tab" left>
              <v-tab v-for="item in tabs" :key="item" :href="`#${item}`">
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items :value="tab">
              <v-tab-item v-for="item in tabs" :key="item" :value="item">
                {{/* Model helper */ }}
                <div v-if="item === 'Models'">

                  <!-- <v-card-title>model</v-card-title> -->
                  <v-card-text>
                    <div v-if="!modelsLoading && models.length === 0" >
                      
                      <v-layout class="justify-center text-subtitle-2 mt-3 text--secondary">
                        To start using Meteron, add your first AI endpoint that generates images or text.
                      </v-layout>
                      <v-layout class="justify-center mt-6 mb-6">
                        <add-model :first="true"></add-model>
                      </v-layout>
                    </div>
                    
                    <v-list v-else dense>
                      <v-list-item>
                        <v-list-item-content>
                          <span class="font-weight-bold">Models</span>
                        </v-list-item-content>
                        <v-list-item-content class="align-end"> </v-list-item-content>
                        <v-list-item-icon>
                          <!-- just for alignment, not editable -->
                          <add-model></add-model>
                        </v-list-item-icon>
                      </v-list-item>

                      <v-list-item v-if="modelsLoading">
                        <v-progress-linear indeterminate color="secondary"></v-progress-linear>
                      </v-list-item>
                      <!-- models list -->
                      <v-list-item v-else v-for="(model, i) in models" v-bind:key="i">
                        <v-list-item-content>
                          <v-list-item-title router>
                            <router-link  class="no-underline" :to="{
                                name: 'modelDetails',                                
                                params: {
                                  modelId: model.id,
                                },
                              }">                              
                              
                              <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                  <span v-on="{ ...tooltip }">
                                    {{ model.name }}
                                  </span>
                                </template>
                                Model type: {{ model.modelType }} • API type: {{ model.apiType }}
                              </v-tooltip>
                            </router-link>
                            <!-- <v-chip class="ma-2" color="primary" outlined> -->
                              
                            <!-- </v-chip> -->
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content v-if="model.servers">
                          <v-list dense>
                            <v-list-group>
                              <template v-slot:activator>
                                <v-list-item-content>
                                  Endpoints ({{ model.servers.length }})
                                </v-list-item-content>
                              </template>

                              <v-list-item v-for="child in model.servers" :key="child.id">
                                <v-list-item-content class="text-truncate">
                                  <div>
                                    <v-icon v-if="child.alive" small color="green accent-3">fiber_manual_record</v-icon>
                                    <v-icon v-else small color="error">
                                      fiber_manual_record
                                    </v-icon>
                                    {{ child.url }}
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-group>
                          </v-list>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-menu class="items" transition="v-slide-y-transition">
                            <template v-slot:activator="{ on: on }">
                              <v-btn icon v-on="on" :class="
                                $store.state.theme === 'light' ? 'secondary--text' : ''
                              ">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>
                              <v-list-item router :to="{
                                  name: 'modelDetails',
                                  params: {
                                    modelId: model.id,
                                  },
                                }">
                              <v-list-item-title>details</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="deleteModelIntent(model)">
                                <v-list-item-title>delete</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action>
                      </v-list-item>                      
                    </v-list>
                  </v-card-text>
                </div>

                {{/* API keys */ }}
                <div v-if="item === 'API Keys'">
                 
                  <v-card-text>
                    <v-list dense>
                      <v-list-item two-line class="mt-1 mb-8">
                        <v-list-item-content>
                          <span class="font-weight-bold">API key</span>
                          <v-list-item-subtitle class="mt-2 text-wrap">
                            Permissions to list, create and delete generated images as well as set QoS tiers. Should be
                            used within your backend.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="project" class="mt-1">
                          <v-text-field v-model="project.apiToken" outlined dense readonly
                            :append-icon="showApiKey ? 'mdi-eye' : 'mdi-eye-off'" :type="showApiKey ? 'text' : 'password'"
                            @click:append="showApiKey = !showApiKey"></v-text-field>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon class="ml-1 mt-5" @click="copyToClipboard(project.apiToken)">
                            mdi-content-copy
                          </v-icon>
                        </v-list-item-icon>
                      </v-list-item>

                      <v-list-item two-line class="mt-1">
                        <v-list-item-content>
                          <span class="font-weight-bold">Public API key</span>
                          <v-list-item-subtitle class="mt-2 text-wrap">
                            Permissions to list and create generated images. Can be used from your frontend.
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="project" class="mt-1">
                          <v-text-field v-model="project.publicApiToken" outlined dense readonly></v-text-field>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon class="ml-1 mt-5" @click="copyToClipboard(project.publicApiToken)">
                            mdi-content-copy
                          </v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </v-card-text>

                </div>

                <div v-if="item === 'Billing'">
                  <billing-account :project="project"></billing-account>
                </div>

                <div v-if="item === 'Usage'">
                  <usage v-if="project && models" :project="project" :serverStats="serverStats" 
                    :imageGenStats="imageGenStats"
                    :textGenStats="textGenStats"
                    :models="models"></usage>
                </div>

              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-container container--fluid class="ma-2 mt-5">
        <v-layout wrap>
          <div class="secondary--text text-subtitle-1 grey--text text--lighten-2">
            Example projects
          </div>
        </v-layout>
      </v-container>      
    </v-layout>

    <v-layout>
      <v-container container--fluid class="">
        <example-projects> </example-projects>
      </v-container>
    </v-layout>

  </div>
</template>

<style>
.no-underline {
  text-decoration: none;
}
</style>

<script>

import AddModel from './AddModel.vue';
import BillingAccount from '../billing/BillingAccount.vue';
import Usage from '../billing/Usage.vue';
import ExampleProjects from './ExampleProjects.vue';

export default {
  components: {
    AddModel,
    BillingAccount,
    Usage,
    ExampleProjects
  },

  data() {
    return {
      tabs: ['Models', 'API Keys', 'Usage', 'Billing'],
      showApiKey: false,
      storage: 'Google Cloud Platform',
      deleteModelModal: false,
      deletingModel: {
        id: '',
        projectId: '',
      },
      statisticsSince: '24h',
      statisticsSinceItems: [
        '24h',
        '7d',
        '30d',
      ]
    }
  },

  watch: {
    removeProjectModal(val) {
      val || this.cancelDeletion()
    },
  },

  mounted() {
    this.refresh()
  },

  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      }
    },
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    projects() {
      return this.$store.state.user.user.projects
    },
    models() {
      return this.$store.state.model.models
    },
    modelsLoading() {
      return this.$store.state.model.loading
    },
    serverStats() {
      let servers = 0
      let failingServers = 0
      for (let model of this.models) {
        servers += model.servers.length
        for (let server of model.servers) {
          if (!server.alive) {
            failingServers++
          }
        }
      }
      return {
        totalServers: servers,
        totalFailingServers: failingServers
      }
    },
    imageGenStats() {
      return this.$store.state.imageGens.stats
    },
    textGenStats() {
      return this.$store.state.imageGens.textStats
    },
    imageGenStatsLoading() {
      return this.$store.state.imageGens.loading
    },
    loading() {
      return this.$store.state.project.loading
    },
    error() {
      return this.$store.state.project.error
    },
  },

  methods: {
    copyToClipboard(value) {
      this.$store.dispatch('Notify', `Copied!`)
      this.$clipboard(value)
    },
    async refresh() {
      await this.$store.dispatch('GetUser')
      this.$store.dispatch('ListModels', { projectId: this.project.id })
      this.$store.dispatch('GetImageGenStats', { projectId: this.project.id, model: '' })
      this.$store.dispatch('GetTextGenStats', { projectId: this.project.id, model: '' })
    },
    deleteModelIntent(model) {
      // assigning removing input val
      this.deletingModel = model
      this.deleteModelModal = true
    },
    async deleteModel(model) {
      await this.$store.dispatch('DeleteModel', model)
      this.deleteModelModal = false
      this.refresh()
    }
  }
}
</script>
